import React from "react";
import { Controller, useFormContext } from "react-hook-form";

import CheckSelect from "../../../../components/CheckSelect";

import {
  DECISION_LIST,
  saveDataOnLocalStorage,
} from "../../../../utils/openingOfLegalEntity";
import { RegistrationFormData } from "../../types";

export default function GeneralQuestions() {
  const { control } = useFormContext<RegistrationFormData>();

  return (
    <section>
      <h4>Perguntas Gerais</h4>
      <Controller
        name="isApartment"
        control={control}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <CheckSelect
            horizontal
            value={value}
            onChange={(v) => {
              onChange(v);
              saveDataOnLocalStorage("isApartment", v);
            }}
            error={error?.message}
            label="O local que a empresa está localizada é um apartamento?"
            list={DECISION_LIST}
          />
        )}
      />
      <Controller
        name="newBuilding"
        control={control}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <CheckSelect
            horizontal
            value={value}
            onChange={(v) => {
              onChange(v);
              saveDataOnLocalStorage("newBuilding", v);
            }}
            error={error?.message}
            label="Edificação nova a ser construída?"
            list={DECISION_LIST}
          />
        )}
      />
      {/* <Controller
        name="partnerLiveIn"
        control={control}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <CheckSelect
            value={value}
            onChange={onChange}
            error={error?.message}
            label="O sócio reside ou residirá no local?"
            list={DECISION_LIST}
          />
        )}
      /> */}
    </section>
  );
}
