import React, { useEffect, useState } from "react";
import { useForm, Controller, useWatch } from "react-hook-form";
import { Button } from "rsuite";
import { yupResolver } from "@hookform/resolvers/yup";

import Input from "../../../../../../components/Input";
import Modal from "../../../../../../components/Modal";
import CheckSelect from "../../../../../../components/CheckSelect";
import FileInput from "../../../../../../components/FileInput";

import { maritalStatus as maritalStatusList } from "../../../../../../utils/openingOfLegalEntity";
import { administratorSchema } from "../../../../schema";
import styles from "./index.module.css";

type CustomFile =
  | {
      url: string;
      type: string;
    }
  | undefined;

interface Administrator {
  name: string;
  nationality: string;
  maritalStatus: string;
  marriageRegime?: string;
  rg?: CustomFile;
  cpf?: CustomFile;
  isCnh: boolean;
  cnh?: CustomFile;
  residencyProof: CustomFile;
}

interface AdministratorModalProps {
  show: boolean;
  closeModal: () => void;
  addNewAdministrator: (newAdministrator: Administrator) => void;
  editAdministrator: (administrator: Administrator) => void;
  selectedAdministratorToEdit?: Administrator;
}

const isKeyOfTypeAdministrator = (
  object: Administrator,
  key: string
): key is keyof Administrator => {
  return key in object;
};

export default function AdministratorModal({
  show,
  closeModal,
  addNewAdministrator,
  editAdministrator,
  selectedAdministratorToEdit,
}: AdministratorModalProps) {
  const [isEditMode, setIsEditMode] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm<Administrator>({
    resolver: yupResolver(administratorSchema),
    shouldUnregister: true,
    defaultValues: {
      isCnh: false,
    },
  });

  const isCnh = useWatch({ control, name: "isCnh" });
  const maritalStatus = useWatch({ control, name: "maritalStatus" });

  useEffect(() => {
    if (selectedAdministratorToEdit) {
      setIsEditMode(true);

      for (const key in selectedAdministratorToEdit) {
        if (isKeyOfTypeAdministrator(selectedAdministratorToEdit, key)) {
          setValue(key, selectedAdministratorToEdit[key]);
        }
      }
    } else {
      setIsEditMode(false);
    }
  }, [selectedAdministratorToEdit]);

  const onSubmit = async () => {
    if (isEditMode) {
      await handleSubmit(editAdministrator)();
    } else {
      await handleSubmit(addNewAdministrator)();
    }
  };

  return (
    <Modal
      title={isEditMode ? "Editar Administrador" : "Adicionar Administrador"}
      open={show}
      closeModal={closeModal}
    >
      <div className={styles.formContainer}>
        <Input
          error={errors.name?.message}
          label="Nome *"
          containerStyle={{ flex: 1 }}
          register={register("name")}
        />
        <div style={{ display: "flex", gap: 20 }}>
          <Input
            containerStyle={{ flex: 1 }}
            label="Nacionalidade *"
            error={errors.nationality?.message}
            register={register("nationality")}
          />
        </div>
        <Controller
          name="maritalStatus"
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <CheckSelect
              horizontal
              label="Estado Civil *"
              value={value}
              error={error?.message}
              onChange={onChange}
              list={maritalStatusList}
            />
          )}
        />

        {maritalStatus === "married" && (
          <Input
            error={errors.marriageRegime?.message}
            label="Regime de Casamento *"
            containerStyle={{ flex: 1 }}
            register={register("marriageRegime")}
            hint="1) a comunhão parcial de bens; 2) a comunhão universal de bens; 3) a separação convencional de bens; 4) a separação obrigatória de bens e; 5) a participação final nos aquestos."
          />
        )}

        <div className={styles.checkbox}>
          <input
            type="checkbox"
            defaultChecked={false}
            {...register("isCnh")}
          />
          Anexar CNH ao invés de RG e CPF?
        </div>

        {!isCnh ? (
          <div style={{ display: "flex", columnGap: 20 }}>
            <Controller
              name="rg"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <FileInput
                  id="administrator-rg"
                  label="RG *"
                  value={value}
                  error={error?.message}
                  onChange={onChange}
                />
              )}
            />
            <Controller
              name="cpf"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <FileInput
                  id="administrator-cpf"
                  label="CPF *"
                  value={value}
                  error={error?.message}
                  onChange={onChange}
                />
              )}
            />
          </div>
        ) : (
          <Controller
            name="cnh"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <FileInput
                id="administrator-cnh"
                label="CNH *"
                value={value}
                error={error?.message}
                onChange={onChange}
              />
            )}
          />
        )}

        <Controller
          name="residencyProof"
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <FileInput
              id="administrator-residency-proof"
              value={value}
              label="Comprovante de Residência *"
              error={error?.message}
              onChange={onChange}
            />
          )}
        />
        <Button onClick={onSubmit}>
          {isEditMode ? "Atualizar" : "Adicionar"}
        </Button>
      </div>
    </Modal>
  );
}
