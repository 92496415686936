import React from "react";
import { useController, useFormContext } from "react-hook-form";
import { FiAlertCircle } from "react-icons/fi";
import { Popover, Whisper } from "rsuite";
import { saveDataOnLocalStorage } from "../../../../utils/openingOfLegalEntity";

import { RegistrationFormData } from "../../types";
import styles from "./index.module.css";

const speaker = (
  <Popover style={{ width: 300 }}>
    Descreva todas as atividades de sua empresa
  </Popover>
);

export default function DescriptionActivity() {
  const { control } = useFormContext<RegistrationFormData>();
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({ control, name: "descriptionActivity" });

  return (
    <section className={styles.container}>
      <Whisper placement="auto" trigger="hover" speaker={speaker}>
        <div className={styles.hintIconContainer}>
          <h4>Objeto Social *</h4>
          <FiAlertCircle className={styles.hintIcon} size={18} />
        </div>
      </Whisper>
      <textarea
        rows={5}
        name="descriptionActivity"
        value={value}
        onChange={onChange}
        onBlur={(e) =>
          saveDataOnLocalStorage("descriptionActivity", e.target.value)
        }
      />
      {!!error && <p className={styles.error}>{error?.message}</p>}
    </section>
  );
}
