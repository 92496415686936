import React from "react";
import { Link } from "react-router-dom";

import styles from "./index.module.css";

export default function FormSentNotice() {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <header>
          <h4>Formulário enviado com sucesso</h4>
          <p>
            Obrigado por preencher o formulário, em breve iremos entrar em
            contato com você.
          </p>
          <div style={{ marginBottom: 6 }}>
            <Link to="/">Voltar</Link>
          </div>
        </header>
      </div>
    </div>
  );
}
