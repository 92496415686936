import React from "react";
import { Modal as RsuiteModal } from "rsuite";

interface ModalProps {
  open: boolean;
  closeModal: () => void;
  title?: string;
  children?: React.ReactNode;
  footer?: React.ReactNode;
}

export default function Modal({
  open,
  closeModal,
  title,
  children,
  footer,
}: ModalProps) {
  return (
    <RsuiteModal open={open} onClose={closeModal}>
      <RsuiteModal.Header>
        <RsuiteModal.Title>{title}</RsuiteModal.Title>
      </RsuiteModal.Header>
      <RsuiteModal.Body>{children}</RsuiteModal.Body>
      <RsuiteModal.Footer>{footer}</RsuiteModal.Footer>
    </RsuiteModal>
  );
}
