import React from "react";
import { Routes, Route } from "react-router-dom";

import Home from "./pages/Home";
import OpeningOfLegalEntity from "./pages/OpeningOfLegalEntity";
import FormSentNotice from "./pages/FormSentNotice";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/abertura-de-pessoa-juridica"
          element={<OpeningOfLegalEntity />}
        />
        <Route path="/mensagem-de-confirmacao" element={<FormSentNotice />} />
      </Routes>
    </div>
  );
}

export default App;
