import * as yup from "yup";
import { RegistrationFormData } from "./types";

const validatePostalCode = (value: string | undefined | boolean) => {
  const rawPostalCode =
    typeof value === "string" ? value.replaceAll(" ", "").replace("-", "") : "";

  return rawPostalCode.length === 8 && !Number.isNaN(Number(rawPostalCode));
};

const validatePhone = (value: string | undefined | boolean) => {
  return typeof value === "string" && value.length >= 14;
};

export const partnerSchema = yup.object().shape({
  name: yup.string().required("Esse campo é obrigatório"),
  nationality: yup.string().required("Esse campo é obrigatório"),
  maritalStatus: yup.string().when("legalPerson", {
    is: "NO",
    then: yup.string().required("Esse campo é obrigatório"),
  }),
  marriageRegime: yup.string().when("maritalStatus", {
    is: "married",
    then: yup.string().required("Esse campo é obrigatório"),
  }),
  investedAmount: yup.string().required("Esse campo é obrigatório"),
  legalPerson: yup.string().required("Esse campo é obrigatório"),
  publicServer: yup.string().when("legalPerson", {
    is: "NO",
    then: yup.string().required("Esse campo é obrigatório"),
  }),
  isCnh: yup.bool().when("legalPerson", {
    is: "NO",
    then: yup.bool().required("Esse campo é obrigatório"),
  }),
  rg: yup
    .mixed()
    .when(["legalPerson", "isCnh"], (legalPerson, isCnh) =>
      legalPerson === "NO" && !isCnh
        ? yup.mixed().required("Esse campo é obrigatório")
        : yup.mixed()
    ),
  cpf: yup
    .mixed()
    .when(["legalPerson", "isCnh"], (legalPerson, isCnh) =>
      legalPerson === "NO" && !isCnh
        ? yup.mixed().required("Esse campo é obrigatório")
        : yup.mixed()
    ),
  cnh: yup.mixed().when("isCnh", {
    is: true,
    then: yup.mixed().required("Esse campo é obrigatório"),
  }),
  cnpj: yup.mixed().when("legalPerson", {
    is: "YES",
    then: yup.mixed().required("Esse campo é obrigatório"),
  }),
  residencyProof: yup.mixed().when("legalPerson", {
    is: "NO",
    then: yup.mixed().required("Esse campo é obrigatório"),
  }),
});

export const administratorSchema = yup.object().shape({
  name: yup.string().required("Esse campo é obrigatório"),
  nationality: yup.string().required("Esse campo é obrigatório"),
  maritalStatus: yup.string().required("Esse campo é obrigatório"),
  marriageRegime: yup.string().when("maritalStatus", {
    is: "married",
    then: yup.string().required("Esse campo é obrigatório"),
  }),
  isCnh: yup.bool().when("legalPerson", {
    is: "NO",
    then: yup.bool().required("Esse campo é obrigatório"),
  }),
  rg: yup.mixed().when("isCnh", {
    is: false,
    then: yup.mixed().required("Esse campo é obrigatório"),
  }),
  cpf: yup.mixed().when("isCnh", {
    is: false,
    then: yup.mixed().required("Esse campo é obrigatório"),
  }),
  cnh: yup.mixed().when("isCnh", {
    is: true,
    then: yup.mixed().required("Esse campo é obrigatório"),
  }),
  residencyProof: yup.mixed().required("Esse campo é obrigatório"),
});

const openingOfLegalEntitySchema = yup.object().shape({
  // Company Data

  name: yup.string().required("Esse campo é obrigatório"),
  alternativeName: yup
    .string()
    .required("Esse campo é obrigatório")
    .notOneOf(
      [yup.ref("name")],
      "O Nome Empresarial Alternativo não pode ser igual ao Nome Empresarial"
    ),
  fantasyName: yup.string().required("Esse campo é obrigatório"),
  phone: yup
    .string()
    .required("Esse campo é obrigatório")
    .test("phone", "Telefone inválido", validatePhone),
  email: yup
    .string()
    .email("Email inválido")
    .required("Esse campo é obrigatório"),
  size: yup.string().required("Esse campo é obrigatório"),
  workingHours: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string(),
        label: yup.string(),
        hours: yup.object().shape({
          start: yup.string().required("Preencha o horário de início"),
          end: yup.string().required("Preencha o horário de término"),
        }),
      })
    )
    .required("Selecione pelo menos um dia de funcionamento")
    .min(1, "Selecione pelo menos um dia de funcionamento"),
  activity: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string(),
        label: yup.string(),
      })
    )
    .required("Selecione pelo menos uma atividade")
    .min(1, "Selecione pelo menos uma atividade"),

  // Description Activity

  descriptionActivity: yup.string().required("Esse campo é obrigatório"),

  // Address Data

  propertyType: yup.string().required("Esse campo é obrigatório"),
  postalCode: yup
    .string()
    .required("Esse campo é obrigatório")
    .test("postalCode", "CEP inválido", validatePostalCode),
  iptu: yup.string().required("Esse campo é obrigatório"),
  streetSuffix: yup.string().required("Esse campo é obrigatório"),
  street: yup.string().required("Esse campo é obrigatório"),
  number: yup.string().required("Esse campo é obrigatório"),
  district: yup.string().required("Esse campo é obrigatório"),
  city: yup.string(),
  complement: yup.string(),
  totalBuildingArea: yup.string().required("Esse campo é obrigatório"),
  usedArea: yup
    .string()
    .required("Esse campo é obrigatório")
    .test(
      "usedArea",
      "Área Utilizada não pode ser maior que a Área Total",
      (value, context) => {
        const parent = context.parent as RegistrationFormData;
        const totalBuildingArea = parent.totalBuildingArea;
        if (value && totalBuildingArea) {
          const valueRaw = value.replaceAll(".", "").replace(",", ".");
          const totalBuildingAreaRaw = totalBuildingArea
            .replaceAll(".", "")
            .replace(",", ".");
          return Number(valueRaw) <= Number(totalBuildingAreaRaw);
        } else return true;
      }
    ),

  // Partners

  partners: yup
    .array()
    .of(partnerSchema)
    .required("Adicione pelo menos um sócio")
    .min(1, "Adicione pelo menos um sócio"),

  // Administrators

  administrators: yup
    .array()
    .of(administratorSchema)
    .required("Adicione pelo menos um administrador")
    .min(1, "Adicione pelo menos um administrador"),

  // General Questions

  isApartment: yup.string().required("Esse campo é obrigatório"),
  newBuilding: yup.string().required("Esse campo é obrigatório"),
});

export default openingOfLegalEntitySchema;
