import { isEqual } from "underscore";
import { RegistrationFormData } from "./../pages/OpeningOfLegalEntity/types";

const isKeyOfRegistrationFormData = (
  value: string
): value is keyof RegistrationFormData =>
  [
    "name",
    "alternativeName",
    "fantasyName",
    "email",
    "phone",
    "size",
    "workingHours",
    "activity",
    "descriptionActivity",
    "partners",
    "administrators",
    "propertyType",
    "postalCode",
    "iptu",
    "streetSuffix",
    "street",
    "number",
    "district",
    "city",
    "complement",
    "totalBuildingArea",
    "usedArea",
    "isApartment",
    "newBuilding",
  ].includes(value);

const filterJustNumbers = (value: string) => {
  const regex = /[^\d,.]/g;
  return value.replace(regex, "");
};

const maskPhone = (value: string) => {
  const filterNumber = /\D/g;
  const regex = /(\d{0,2})(\d{0,4})(\d{0,5})/;
  const result = value.replace(filterNumber, "").match(regex);
  const newValue = !result
    ? value
    : !result[2]
    ? result[1]
    : `(${result[1]}) ${result[2]}${
        !result[3]
          ? ""
          : result[3].length === 5
          ? result[3][0] + "-" + result[3].slice(1, 5)
          : "-" + result[3]
      }`;
  return newValue;
};

const maskCurrency = (value: string) => {
  const filterNumber = /\D/g;
  const result = value.replace(filterNumber, "");
  let newValue =
    result.length >= 3
      ? `${result.slice(0, result.length - 2)},${result.slice(
          result.length - 2,
          result.length
        )}`
      : result;

  if (newValue.length > 6) {
    const valueWithoutDecimal = newValue.slice(0, newValue.length - 3);
    const valueReversed = valueWithoutDecimal.split("").reverse().join("");
    const valueSplitPerThree = valueReversed.match(/.{1,3}/g) as string[];
    const valueWithDot = valueSplitPerThree
      ?.join(".")
      .split("")
      .reverse()
      .join("");
    newValue = `${valueWithDot},${newValue.slice(
      newValue.length - 2,
      newValue.length
    )}`;
  }

  return newValue;
};

const transformFileToBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

const uniqueId = (length = 16) => {
  return parseInt(
    Math.ceil(Math.random() * Date.now())
      .toPrecision(length)
      .toString()
      .replace(".", "")
  );
};

function saveDataOnLocalStorage<K extends keyof RegistrationFormData>(
  name: K,
  value: RegistrationFormData[K]
) {
  if (isKeyOfRegistrationFormData(name)) {
    const savedData = JSON.parse(
      localStorage.getItem("openingOfLegalEntity") || "{}"
    ) as RegistrationFormData;
    if (!isEqual(savedData[name], value)) {
      // console.log(`save ${name} on local storage: `, value);
      savedData[name] = value;
      localStorage.setItem("openingOfLegalEntity", JSON.stringify(savedData));
    }
  }
}

type Entry<O, K extends keyof O> = [K, O[K]];
type Entries<O> = Entry<O, keyof O>[];
export type RegistrationFormDataEntries = Entries<RegistrationFormData>;
const getDataEntriesOnLocalStorage = () => {
  const localStorageData = JSON.parse(
    localStorage.getItem("openingOfLegalEntity") || "{}"
  ) as RegistrationFormData;
  return Object.entries(localStorageData) as RegistrationFormDataEntries;
};

const CONTACT_MESSAGE =
  "Olá, estou com dúvidas sobre o formulário de abertura de pessoa jurídica";

// const COMPANY_SIZE_HINT = (
//   <ul className= { styles.listContainer } >
//   <li>
//   Micro Empresa (Empresa com faturamento anual até R$ 360.000, 00 ao ano)
// </li>
//   < li >
//   Empresa de Pequeno Porte(Empresa com faturamento anual de R$ 360.000, 01 a
//         R$ 4.800.000, 00 ao ano)
//     < /li>
//     < li > Outros(Empresa com faturamento anual superior a R$ 4.800.000, 00) < /li>
//     < /ul>
//   );

const COMPANY_SIZE_LIST = [
  {
    value: "ME",
    label: "Micro Empresa",
    hint: "Empresa com faturamento de até R$ 360.000,00 ao ano.",
  },
  {
    value: "EPP",
    label: "Empresa de Pequeno Porte",
    hint: "Empresa com faturamento anual de R$ 360.000,01 a R$ 4.800.000,00.",
  },
  {
    value: "OTHERS",
    label: "Outros",
    hint: "Empresa com faturamento anual superior a R$ 4.800.000,00.",
  },
];

const PROPERTY_TYPE_LIST = [
  { value: "Rural", label: "Rural" },
  { value: "Urban", label: "Urbano" },
  { value: "Unregulated", label: "Sem Regularização" },
];

const DECISION_LIST = [
  { value: "YES", label: "Sim" },
  { value: "NO", label: "Não" },
];

const ACTIVITY_LIST = [
  { value: "Estabelecimento fixo", label: "Estabelecimento fixo" },
  { value: "Internet", label: "Internet" },
  { value: "Em local fixo fora de loja", label: "Em local fixo fora de loja" },
  { value: "Correio", label: "Correio" },
  {
    value: "Porta a Porta, Postos Móveis ou por Ambulantes",
    label: "Porta a Porta, Postos Móveis ou por Ambulantes",
  },
  { value: "Televendas", label: "Televendas" },
  { value: "Máquinas Automáticas", label: "Máquinas Automáticas" },
  {
    value: "Atividade Desenvolvida Fora do Estabelecimento",
    label: "Atividade Desenvolvida Fora do Estabelecimento",
  },
  { value: "Sede", label: "Sede" },
  { value: "Escritório Administrativo", label: "Escritório Administrativo" },
  { value: "Depósito Fechado", label: "Depósito Fechado" },
  { value: "Almoxarifado", label: "Almoxarifado" },
  { value: "Oficina de Reparação", label: "Oficina de Reparação" },
  { value: "Garagem", label: "Garagem" },
  {
    value: "Unidade de Abastecimento de Combustíveis",
    label: "Unidade de Abastecimento de Combustíveis",
  },
  { value: "Ponto de Exposição", label: "Ponto de Exposição" },
  { value: "Centro de Treinamento", label: "Centro de Treinamento" },
  {
    value: "Centro de Processamento de Dados",
    label: "Centro de Processamento de Dados",
  },
  { value: "Posto de Serviço", label: "Posto de Serviço" },
  { value: "Posto de Coleta", label: "Posto de Coleta" },
];

const daysOfWeek = [
  { value: "mon", label: "SEG" },
  { value: "tue", label: "TER" },
  { value: "wed", label: "QUA" },
  { value: "thu", label: "QUI" },
  { value: "fri", label: "SEX" },
  { value: "sat", label: "SAB" },
  { value: "sun", label: "DOM" },
];

const maritalStatus = [
  { value: "married", label: "Casado" },
  { value: "single", label: "Solteiro" },
  { value: "separated", label: "Separado" },
  { value: "divorced", label: "Divorciado" },
  { value: "widower", label: "Viúvo" },
  { value: "stable union", label: "União Estável" },
];

export {
  isKeyOfRegistrationFormData,
  filterJustNumbers,
  maskPhone,
  maskCurrency,
  transformFileToBase64,
  uniqueId,
  saveDataOnLocalStorage,
  getDataEntriesOnLocalStorage,
  CONTACT_MESSAGE,
  COMPANY_SIZE_LIST,
  PROPERTY_TYPE_LIST,
  DECISION_LIST,
  ACTIVITY_LIST,
  daysOfWeek,
  maritalStatus,
};
