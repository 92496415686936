import React, { useState, useEffect } from "react";
import Select, { GroupBase, StylesConfig } from "react-select";
import { Whisper, Popover } from "rsuite";
import { FieldError } from "react-hook-form";
import { FiAlertCircle } from "react-icons/fi";
import { useImmer } from "use-immer";
import { isEqual } from "underscore";
import dayjs from "dayjs";

import { daysOfWeek } from "../../../../../../utils/openingOfLegalEntity";
import styles from "./index.module.css";

type ListItem = {
  value: string;
  label: string;
  hours?: {
    start?: string;
    end?: string;
  };
};

type Item = {
  value: string;
  label: string;
};

type HoursFieldsError = {
  hours: {
    start: FieldError;
    end: FieldError;
  };
};

interface ItemProps {
  hours: ListItem["hours"];
  label: string;
  index: number;
  checked: boolean;
  error?: HoursFieldsError;
  handleChange: (index: number) => void;
  handleChangeHours: (selectIndex: 0 | 1, index: number, hour?: string) => void;
}

interface MultiSelectWorkingHoursProps {
  label?: string;
  value?: ListItem[];
  onChange?: (value: ListItem[]) => void;
  hint?: string | JSX.Element;
  error?: FieldError | HoursFieldsError[];
  containerStyle?: React.CSSProperties;
}

const isOfTypeFieldError = (
  value: MultiSelectWorkingHoursProps["error"]
): value is FieldError => (value as FieldError)?.type !== undefined;

const isOfTypeFieldErrorArray = (
  value: MultiSelectWorkingHoursProps["error"]
): value is HoursFieldsError[] =>
  (value as HoursFieldsError[])?.length !== undefined;

const createTimeList30minApart = () => {
  const hoursInTheDay = 24;
  const list: Item[] = [];

  for (let actualHour = 0; actualHour < hoursInTheDay; actualHour++) {
    const actualHourInDayjs = dayjs().startOf("day").hour(actualHour);
    const actualHourInDayjsPlus30min = actualHourInDayjs.add(30, "minutes");
    const actualHourFormated = actualHourInDayjs.format("HH:mm");
    const actualHourFormatedPlus30min =
      actualHourInDayjsPlus30min.format("HH:mm");
    list.push({ value: actualHourFormated, label: actualHourFormated });
    list.push({
      value: actualHourFormatedPlus30min,
      label: actualHourFormatedPlus30min,
    });
  }

  return list;
};

const selectStyles: (
  haveError: boolean
) => StylesConfig<Item, false, GroupBase<Item>> = (haveError) => ({
  control: (styles) => ({
    ...styles,
    minWidth: 110,
    borderColor: haveError ? "red" : "#ccc",
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    backgroundColor: haveError ? "red" : "#ccc",
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    color: haveError ? "red" : "#ccc",
  }),
  placeholder: (styles) => ({ ...styles, color: haveError ? "red" : "#ccc" }),
});

const Item = ({
  label,
  hours,
  index,
  checked,
  error,
  handleChange,
  handleChangeHours,
}: ItemProps) => {
  const [hour1, setHour1] = useState<Item | null>();
  const [hour2, setHour2] = useState<Item | null>();
  const [hoursOfDayList, setHoursOfDayList] = useState<Item[]>();

  useEffect(() => {
    setHoursOfDayList(createTimeList30minApart());
  }, []);

  useEffect(() => {
    if (hours) {
      const { start, end } = hours;
      if (start && start !== hour1?.value)
        setHour1({ label: start, value: start });
      if (end && end !== hour2?.value) setHour2({ label: end, value: end });
    }
  }, [hours]);

  const handleChangeHoursValue = (selectIndex: 0 | 1, item: Item | null) => {
    if (selectIndex === 0) {
      setHour1(item);
    } else {
      setHour2(item);
    }

    handleChangeHours(selectIndex, index, item?.value);
  };

  useEffect(() => {
    if (checked === false) {
      setHour1(null);
      setHour2(null);
    }
  }, [checked]);

  return (
    <>
      <div className={styles.item}>
        <div className={styles.itemLabelContainer}>
          <input
            checked={checked}
            type="checkbox"
            onChange={() => handleChange(index)}
          />
          <label>{label}</label>
        </div>
        <Select
          value={hour1}
          options={hoursOfDayList}
          placeholder="Horário"
          styles={selectStyles(!!error?.hours?.start)}
          isDisabled={!checked}
          onChange={(value) => handleChangeHoursValue(0, value)}
        />
        <p style={{ margin: "0 8px" }}>às</p>
        <Select
          value={hour2}
          options={hoursOfDayList}
          placeholder="Horário"
          styles={selectStyles(!!error?.hours?.end)}
          isDisabled={!checked}
          onChange={(value) => handleChangeHoursValue(1, value)}
        />
      </div>
      {/* {!!error && (error.hours.start || error.hours.end) &&
        <p className={styles.error} style={{ marginLeft: 10 }}>{error.hours.start?.message || error.hours.end?.message}</p>} */}
    </>
  );
};

const getSelectedIndexByDaysOfWeekIndex = (value: ListItem[], index: number) =>
  value.findIndex((item) => item.value === daysOfWeek[index].value);

export default function MultiSelectWorkingHours({
  label,
  value,
  hint,
  error,
  containerStyle,
  onChange,
}: MultiSelectWorkingHoursProps) {
  const [selected, setSelected] = useImmer<ListItem[] | null>(null);
  const speaker = <Popover style={{ width: 300 }}>{hint}</Popover>;

  useEffect(() => {
    if (value && !isEqual(selected, value)) {
      setSelected((draft) => [...(draft || []), ...value]);
    }
  }, [value]);

  useEffect(() => {
    if (onChange && selected && !isEqual(selected, value)) {
      onChange(selected);
    }
  }, [selected]);

  const isChecked = (value: string) => {
    if (!selected) return false;
    return selected.findIndex((item) => item.value === value) !== -1;
  };

  const handleChange = (index: number) => {
    const selectedDay = daysOfWeek[index];

    setSelected((draft) => {
      draft ||= [];

      if (isChecked(selectedDay.value)) {
        draft = draft.filter((item) => item.value !== selectedDay.value);
      } else {
        draft.push(selectedDay);
      }

      return draft;
    });
  };

  const handleChangeHours = (
    selectIndex: 0 | 1,
    index: number,
    hour?: string
  ) => {
    const selectedDay = daysOfWeek[index];

    setSelected((draft) => {
      draft ||= [];
      const selectedDayIndex = draft.findIndex(
        (item) => item.value === selectedDay.value
      );
      const selectedHours = draft[selectedDayIndex].hours || {
        start: undefined,
        end: undefined,
      };

      if (selectIndex === 0) {
        selectedHours.start = hour;
      } else {
        selectedHours.end = hour;
      }

      draft[selectedDayIndex].hours = selectedHours;
      return draft;
    });
  };

  return (
    <div className={styles.container} style={containerStyle}>
      <div className={styles.labelContainer}>
        <label>{label}</label>
        {!!hint && (
          <Whisper placement="auto" trigger="hover" speaker={speaker}>
            <div>
              <FiAlertCircle style={{ marginLeft: 4 }} />
            </div>
          </Whisper>
        )}
      </div>
      <div className={styles.itemsContainer}>
        {daysOfWeek.map((item, index) => (
          <Item
            key={item.value}
            index={index}
            error={
              isOfTypeFieldErrorArray(error) && selected
                ? error[getSelectedIndexByDaysOfWeekIndex(selected, index)]
                : undefined
            }
            checked={isChecked(item.value)}
            handleChange={handleChange}
            handleChangeHours={handleChangeHours}
            hours={
              selected?.[getSelectedIndexByDaysOfWeekIndex(selected, index)]
                ?.hours
            }
            {...item}
          />
        ))}
      </div>
      {isOfTypeFieldError(error) && error?.message && (
        <p className={styles.error}>{error?.message}</p>
      )}
    </div>
  );
}
