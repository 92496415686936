import React, { useState } from "react";
import { Whisper, Popover, Loader } from "rsuite";
import { FiAlertCircle, FiSearch, FiX } from "react-icons/fi";
import { UseFormRegisterReturn } from "react-hook-form";

import styles from "./index.module.css";

interface InputProps {
  label?: string;
  hint?: string;
  error?: string;
  containerStyle?: React.CSSProperties;
  register?: UseFormRegisterReturn;
  type?: React.HTMLInputTypeAttribute;
  disabled?: boolean;
  disabledWithActivationOption?: boolean;
  searchIcon?: boolean;
  isMonetary?: boolean;
  search?: (setLoading: (loading: boolean) => void) => void;
  clearField?: () => void;
}

export default function Input({
  label,
  hint,
  error,
  containerStyle,
  register,
  type,
  disabled,
  disabledWithActivationOption,
  searchIcon,
  isMonetary,
  search,
  clearField,
}: InputProps) {
  const [searchLoading, setSearchLoading] = useState(false);
  const speaker = <Popover style={{ width: 300 }}>{hint}</Popover>;

  return (
    <div className={styles.container} style={containerStyle}>
      <div className={styles.labelContainer}>
        {!hint ? (
          <label>{label}</label>
        ) : (
          <Whisper placement="auto" trigger="hover" speaker={speaker}>
            <div>
              <label>{label}</label>
              <FiAlertCircle style={{ marginLeft: 4 }} />
            </div>
          </Whisper>
        )}
      </div>
      <div className={styles.inputContainer}>
        {isMonetary && <span className={styles.currency}>R$</span>}
        <input
          disabled={searchLoading || disabledWithActivationOption || disabled}
          style={{
            borderColor: error && "red",
            paddingLeft: isMonetary ? 20 : 0,
          }}
          type={type}
          {...register}
        />
        <div className={styles.searchIconContainer}>
          {searchIcon &&
            (searchLoading ? (
              <Loader style={{ marginLeft: 10 }} />
            ) : disabledWithActivationOption ? (
              <FiX
                onClick={() => {
                  clearField && clearField();
                }}
                style={{ marginLeft: 10 }}
                cursor="pointer"
                size={20}
              />
            ) : (
              <FiSearch
                onClick={() => {
                  search && search(setSearchLoading);
                }}
                style={{ marginLeft: 10 }}
                cursor="pointer"
                size={20}
              />
            ))}
        </div>
      </div>
      {!!error && <p className={styles.error}>{error}</p>}
    </div>
  );
}
