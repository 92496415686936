import React, { forwardRef, useEffect, useState } from "react";
import { FiCamera } from "react-icons/fi";

import { Loader } from "rsuite";
import Alert from "../Alert";

import { uploadFile } from "../../pages/OpeningOfLegalEntity/actions";
import pdfIconImage from "../../assets/images/pdf-icon-thumb.png";
import styles from "./index.module.css";

interface FileInputProps {
  id: string;
  value?: CustomFile;
  label?: string;
  error?: string;
  containerStyle?: React.CSSProperties;
  labelStyle?: React.CSSProperties;
  inputStyle?: React.CSSProperties;
  onChange?: (file?: CustomFile) => void;
}

type CustomFile =
  | {
      url: string;
      type: string;
    }
  | undefined;

const FileInput = (
  {
    id,
    value,
    label,
    containerStyle,
    labelStyle,
    inputStyle,
    error,
    onChange,
  }: FileInputProps,
  ref: React.LegacyRef<HTMLInputElement>
) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [previewFileUrl, setPreviewFileUrl] = useState<string>();

  useEffect(() => {
    if (value) {
      definePreviewFileUrl(value);
    }
  }, [value]);

  const definePreviewFileUrl = (file: CustomFile) => {
    if (file) {
      if (file.type !== "application/pdf") {
        setPreviewFileUrl(file.url);
      } else {
        setPreviewFileUrl(pdfIconImage);
      }
    } else {
      setPreviewFileUrl(undefined);
    }
  };

  const sendImageAndGetUrl = async (file: File) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("path", "");
      const url = await uploadFile(formData);

      if (url) {
        return url;
      } else {
        throw new Error("Error while uploading file");
      }
    } catch (error) {
      const message =
        "Ocorreu um erro inesperado ao salvar as imagens no servidor.";
      Alert({ type: "error", message, placement: "topStart", duration: 5000 });
    }
  };

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    const file = e.target.files?.[0];
    let formattedFile: CustomFile;

    if (file) {
      const fileUrl = await sendImageAndGetUrl(file);
      if (!fileUrl) return;
      formattedFile = {
        url: fileUrl,
        type: file.type,
      };
    }

    definePreviewFileUrl(formattedFile);
    onChange?.(formattedFile);
    setLoading(false);
  };

  return (
    <div className={styles.inputContainer} style={containerStyle}>
      <label style={labelStyle}>{label}</label>
      <div className={styles.buttonWrap}>
        <label
          className={
            error
              ? styles.buttonError
              : !previewFileUrl
              ? styles.button
              : styles.buttonWithAttachment
          }
          htmlFor={id}
        >
          {loading ? (
            <Loader />
          ) : !previewFileUrl ? (
            <FiCamera />
          ) : (
            <img src={previewFileUrl} />
          )}
        </label>
        <input
          id={id}
          type="file"
          accept="image/png,image/jpg,image/jpeg,application/pdf"
          ref={ref}
          style={inputStyle}
          disabled={loading}
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

export default forwardRef(FileInput);
