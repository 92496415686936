import * as Realm from "realm-web";

const app = new Realm.App({ id: process.env.REACT_APP_REALM_APP_ID || "" });

const loginApiKey = async () => {
  const apiKey = process.env.REACT_APP_REALM_APY_KEY || "";
  const credentials = Realm.Credentials.apiKey(apiKey);
  try {
    const user = await app.logIn(credentials);
    return user;
  } catch (error) {
    console.log("Failed to log in", error);
  }
};

const getCurrentUser = async () => {
  const user = app.currentUser;
  if (user) {
    return user;
  } else {
    return await loginApiKey();
  }
};

export { getCurrentUser };
