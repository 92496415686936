import React, { useEffect, useState } from "react";
import { Whisper, Popover } from "rsuite";
import { FiAlertCircle } from "react-icons/fi";
import ReactSelect, { GroupBase, MultiValue, StylesConfig } from "react-select";

import styles from "./index.module.css";

type Item = {
  value: string;
  label: string;
};

interface SelectProps {
  label?: string;
  placeholder?: string;
  value?: MultiValue<Item> | Item;
  options?: MultiValue<Item>;
  hint?: string;
  error?: string;
  isDisabled?: boolean;
  menuPortalTarget?: HTMLElement | null;
  containerStyle?: React.CSSProperties;
  selectStyles?: StylesConfig<Item, false, GroupBase<Item>>;
  isMulti?: boolean;
  onChange?: (value?: MultiValue<Item> | Item) => void;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
}

export default function Select({
  label,
  placeholder,
  value,
  options,
  hint,
  error,
  isDisabled,
  menuPortalTarget,
  containerStyle,
  isMulti,
  onChange,
  onBlur,
}: SelectProps) {
  const [selected, setSelected] = useState<MultiValue<Item> | Item>();
  const speaker = <Popover style={{ width: 300 }}>{hint}</Popover>;

  useEffect(() => {
    if (value) {
      setSelected(value);
    }
  }, [value]);

  return (
    <div className={styles.container} style={containerStyle}>
      <div className={styles.labelContainer}>
        <label>{label}</label>
        {!!hint && (
          <Whisper placement="auto" trigger="hover" speaker={speaker}>
            <div>
              <FiAlertCircle style={{ marginLeft: 4 }} />
            </div>
          </Whisper>
        )}
      </div>
      <div
        className={error ? styles.selectContainerError : styles.selectContainer}
      >
        <ReactSelect
          value={selected}
          isMulti={isMulti}
          closeMenuOnSelect={false}
          options={options}
          placeholder={placeholder}
          isDisabled={isDisabled}
          menuPortalTarget={menuPortalTarget}
          onChange={(newValue) => onChange?.(newValue || undefined)}
          onBlur={onBlur}
        />
      </div>
      {!!error && <p className={styles.error}>{error}</p>}
    </div>
  );
}
