import React from "react";
import { Link } from "react-router-dom";

import styles from "./index.module.css";

export default function Home() {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <header>
          <h2>Formulários Royal Advice</h2>
          <p>
            Bem vindo ao Formulários da Royal Advice, este site é reservado para
            a coleta de dados de nossos clientes.
          </p>
        </header>
        <section>
          <h4>Formulários:</h4>
          <ul>
            <li>
              <Link to="abertura-de-pessoa-juridica">
                Abertura de Pessoa Jurídica
              </Link>
            </li>
          </ul>
        </section>
      </div>
    </div>
  );
}
