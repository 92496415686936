import React from "react";
import { Controller, useFormContext } from "react-hook-form";

import Input from "../../../../components/Input";
import CheckSelect from "../../../../components/CheckSelect";
import Select from "../../../../components/Select";
import MultiCheckSelect from "../../../../components/MultiCheckSelect";
import MultiSelectWorkingHours from "./components/MultiSelectWorkingHours";

import {
  COMPANY_SIZE_LIST,
  ACTIVITY_LIST,
  maskPhone,
  isKeyOfRegistrationFormData,
  saveDataOnLocalStorage,
} from "../../../../utils/openingOfLegalEntity";
import { RegistrationFormData } from "../../types";

export default function CompanyData() {
  const {
    register,
    setValue,
    control,
    formState: { errors },
  } = useFormContext<RegistrationFormData>();

  const handleOnChangeMarkPhone = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = maskPhone(e.target.value);

    e.target.value = value;
    if (isKeyOfRegistrationFormData(name)) {
      setValue(name, value, { shouldValidate: true });
    }
  };

  const handleOnBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;
    if (isKeyOfRegistrationFormData(name)) {
      saveDataOnLocalStorage(name, value);
    }
  };

  return (
    <section>
      <h4>Dados da Empresa</h4>
      <Input
        error={errors.name?.message}
        label="Nome Empresarial *"
        register={register("name", { onBlur: handleOnBlur })}
      />
      <Input
        error={errors.alternativeName?.message}
        label="Nome Empresarial Alternativo *"
        register={register("alternativeName", { onBlur: handleOnBlur })}
        hint="Preencha este campo para caso o nome empresarial acima não esteja disponível na junta comercial."
      />
      <Input
        error={errors.fantasyName?.message}
        label="Nome Fantasia *"
        register={register("fantasyName", { onBlur: handleOnBlur })}
        hint="O título do estabelecimento, podendo ser o mesmo do nome empresarial."
      />
      <Input
        error={errors.email?.message}
        label="Email *"
        register={register("email", { onBlur: handleOnBlur })}
      />
      <Input
        error={errors.phone?.message}
        label="Telefone *"
        register={register("phone", {
          onChange: handleOnChangeMarkPhone,
          onBlur: handleOnBlur,
        })}
      />
      <Controller
        name="size"
        control={control}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <CheckSelect
            value={value}
            onChange={(v) => {
              onChange(v);
              saveDataOnLocalStorage("size", v);
            }}
            error={error?.message}
            label="Selecione o porte da empresa *"
            list={COMPANY_SIZE_LIST}
          />
        )}
      />
      <Controller
        name="workingHours"
        control={control}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <MultiSelectWorkingHours
            value={value}
            onChange={(v) => {
              onChange(v);
              saveDataOnLocalStorage("workingHours", v);
            }}
            error={error}
            label="Horário de Funcionamento *"
          />
        )}
      />
      <Controller
        name="activity"
        control={control}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <Select
            isMulti
            value={value}
            onChange={onChange}
            onBlur={() => saveDataOnLocalStorage("activity", value)}
            error={error?.message}
            label="Tipo de Empreendimento *"
            placeholder="Selecione o tipo de empreendimento, pode ser mais de um"
            options={ACTIVITY_LIST}
          />
        )}
      />
    </section>
  );
}
