import React from "react";
import { Message, toaster } from "rsuite";
import { TypeAttributes } from "rsuite/esm/@types/common";
import { PlacementType } from "rsuite/esm/toaster/ToastContainer";

interface Alert {
  type: TypeAttributes.Status;
  message: string;
  duration?: number;
  placement?: PlacementType;
  onClose?: () => void;
}

export default function Alert({
  type,
  message,
  placement,
  duration = 3000,
  onClose,
}: Alert): string | undefined {
  const ToasterMessage = (
    <Message onClose={onClose} type={type} duration={duration} closable>
      {message}
    </Message>
  );

  return toaster.push(ToasterMessage, { placement });
}
