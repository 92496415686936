import React, { useEffect, useState } from "react";
import { useForm, Controller, useWatch } from "react-hook-form";
import { Button } from "rsuite";
import { yupResolver } from "@hookform/resolvers/yup";

import Input from "../../../../../../components/Input";
import Modal from "../../../../../../components/Modal";
import CheckSelect from "../../../../../../components/CheckSelect";
import FileInput from "../../../../../../components/FileInput";

import {
  maritalStatus as maritalStatusList,
  maskCurrency,
  DECISION_LIST,
} from "../../../../../../utils/openingOfLegalEntity";
import { partnerSchema } from "../../../../schema";
import { Partner } from "../../../../types";

import styles from "./index.module.css";

interface PartnerModalProps {
  show: boolean;
  closeModal: () => void;
  addNewPartner: (newPartner: Partner, isAdministrator: boolean) => void;
  editPartner: (partner: Partner, isAdministrator: boolean) => void;
  selectedPartnerToEdit?: Partner;
}

const isKeyOfTypePartner = (
  object: Partner,
  key: string
): key is keyof Partner => {
  return key in object;
};

export default function PartnerModal({
  show,
  closeModal,
  addNewPartner,
  editPartner,
  selectedPartnerToEdit,
}: PartnerModalProps) {
  const [isAdministrator, setIsAdministrator] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm<Partner>({
    resolver: yupResolver(partnerSchema),
    shouldUnregister: true,
    defaultValues: {
      isCnh: false,
    },
  });

  const maritalStatus = useWatch({ control, name: "maritalStatus" });
  const legalPerson = useWatch({ control, name: "legalPerson" });
  const publicServer = useWatch({ control, name: "publicServer" });
  const isCnh = useWatch({ control, name: "isCnh" });

  useEffect(() => {
    if (selectedPartnerToEdit) {
      setIsEditMode(true);
      for (const key in selectedPartnerToEdit) {
        if (isKeyOfTypePartner(selectedPartnerToEdit, key)) {
          setValue(key, selectedPartnerToEdit[key]);
        }
      }
    } else {
      setIsEditMode(false);
    }
  }, [selectedPartnerToEdit]);

  useEffect(() => {
    if (legalPerson === "YES" && publicServer === "YES") {
      setIsAdministrator(false);
    }
  }, [legalPerson, publicServer]);

  const onSubmit = async () => {
    if (isEditMode) {
      await handleSubmit((partner) => editPartner(partner, isAdministrator))();
    } else {
      await handleSubmit((newPartner) =>
        addNewPartner(newPartner, isAdministrator)
      )();
    }

    setIsAdministrator(false);
  };

  const handleOnChangeMaskCurrency = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const name = e.target.name;
    const value = maskCurrency(e.target.value);

    e.target.value = value;
    if (name === "investedAmount") {
      setValue(name, value);
    }
  };

  return (
    <Modal
      open={show}
      title={isEditMode ? "Editar Sócio" : "Adicionar Sócio"}
      closeModal={closeModal}
      footer={
        <div className={styles.footer}>
          <div>
            {legalPerson === "NO" && publicServer === "NO" && (
              <div className={styles.checkbox}>
                <input
                  checked={isAdministrator}
                  type="checkbox"
                  onChange={(e) => setIsAdministrator(e.target.checked)}
                />
                Este sócio é adminsitrador?
              </div>
            )}
          </div>

          <Button
            style={{ justifySelf: "end" }}
            className={styles.button}
            onClick={onSubmit}
          >
            {isEditMode ? "Atualizar" : "Adicionar"}
          </Button>
        </div>
      }
    >
      <div className={styles.formContainer}>
        <Input
          error={errors.name?.message}
          label="Nome *"
          register={register("name")}
        />
        <div style={{ display: "flex", gap: 20 }}>
          <Input
            containerStyle={{ flex: 1 }}
            label="Nacionalidade *"
            error={errors.nationality?.message}
            register={register("nationality")}
          />
          <Input
            isMonetary
            containerStyle={{ flex: 1 }}
            error={errors.investedAmount?.message}
            hint="Valor que o sócio investira na empresa"
            label="Capital Social *"
            register={register("investedAmount", {
              onChange: handleOnChangeMaskCurrency,
            })}
          />
        </div>

        <Controller
          name="legalPerson"
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <CheckSelect
              horizontal
              label="Pessoa Jurídica *"
              value={value}
              error={error?.message}
              onChange={onChange}
              list={DECISION_LIST}
            />
          )}
        />

        {legalPerson === "NO" && (
          <Controller
            name="publicServer"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <CheckSelect
                horizontal
                label="Servidor Público *"
                value={value}
                error={error?.message}
                onChange={onChange}
                list={DECISION_LIST}
              />
            )}
          />
        )}

        {legalPerson === "NO" && (
          <Controller
            name="maritalStatus"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <CheckSelect
                horizontal
                label="Estado Civil *"
                value={value}
                error={error?.message}
                onChange={onChange}
                list={maritalStatusList}
              />
            )}
          />
        )}

        {maritalStatus === "married" && (
          <Input
            error={errors.marriageRegime?.message}
            label="Regime de Casamento *"
            containerStyle={{ flex: 1 }}
            register={register("marriageRegime")}
            hint="1) a comunhão parcial de bens; 2) a comunhão universal de bens; 3) a separação convencional de bens; 4) a separação obrigatória de bens e; 5) a participação final nos aquestos."
          />
        )}

        {legalPerson === "NO" ? (
          <>
            <strong style={{ color: "#2e2e2e" }}>Documentos *</strong>
            <div className={styles.checkbox}>
              <input
                type="checkbox"
                defaultChecked={false}
                {...register("isCnh")}
              />
              Anexar CNH ao invés de RG e CPF?
            </div>

            {!isCnh ? (
              <div style={{ display: "flex", columnGap: 20 }}>
                <Controller
                  name="rg"
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <FileInput
                      id="partner-rg"
                      label="RG *"
                      value={value}
                      error={error?.message}
                      onChange={onChange}
                    />
                  )}
                />
                <Controller
                  name="cpf"
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <FileInput
                      id="partner-cpf"
                      label="CPF *"
                      value={value}
                      error={error?.message}
                      onChange={onChange}
                    />
                  )}
                />
              </div>
            ) : (
              <Controller
                name="cnh"
                control={control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <FileInput
                    id="partner-cnh"
                    label="CNH *"
                    value={value}
                    error={error?.message}
                    onChange={onChange}
                  />
                )}
              />
            )}

            <Controller
              name="residencyProof"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <FileInput
                  id="partner-residency-proof"
                  value={value}
                  label="Comprovante de Residência *"
                  error={error?.message}
                  onChange={onChange}
                />
              )}
            />
          </>
        ) : (
          legalPerson === "YES" && (
            <>
              <Controller
                name="cnpj"
                control={control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <FileInput
                    id="partner-cnpj"
                    value={value}
                    label="CNPJ *"
                    error={error?.message}
                    onChange={onChange}
                  />
                )}
              />
            </>
          )
        )}
      </div>
    </Modal>
  );
}
