import axios from "axios";

import { getCurrentUser } from "../../realm/connection";
import { RegistrationFormData } from "./types";

interface RegistrationFormDataToSend
  extends Omit<RegistrationFormData, "partners" | "administrators"> {
  partners: {
    name: string;
    nationality: string;
    maritalStatus?: string;
    investedAmount: string;
    rg?: string;
    cpf?: string;
    residencyProof?: string;
    cnpj?: string;
    cnh?: string;
  }[];
  administrators: {
    name: string;
    nationality: string;
    maritalStatus: string;
    rg?: string;
    cpf?: string;
    residencyProof?: string;
    cnh?: string;
  }[];
}

type UploadFileResponse = {
  data: {
    url: string;
  };
};

export const saveOpeningOfLegalEntity = async (
  data: RegistrationFormDataToSend
) => {
  const user = await getCurrentUser();

  if (user) {
    const mongo = user.mongoClient("mongodb-atlas");
    const openeningOfLegalEntity = mongo
      .db("forms")
      .collection("openingOfLegalEntity");

    await openeningOfLegalEntity.insertOne(data);
  } else {
    throw new Error("User not found");
  }
};

export const uploadFile = async (
  data: FormData
): Promise<string | undefined> => {
  const url = process.env.REACT_APP_END_POINT_IMAGE_URL || "";

  const response: UploadFileResponse = await axios.post(url, data, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  return response.data.url.replace(
    "https://royaladvice-forms-upload.s3.sa-east-1.amazonaws.com",
    process.env.REACT_APP_IMAGE_BASE_URL ?? ""
  );
};
