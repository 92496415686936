import React, { useState, useEffect } from "react";
import { Whisper, Popover } from "rsuite";
import { FiAlertCircle } from "react-icons/fi";

import styles from "./index.module.css";

interface CheckSelectProps {
  label?: string;
  value?: string;
  onChange?: (value: string) => void;
  list?: {
    value: string;
    label: string;
    hint?: string;
  }[];
  hint?: string | JSX.Element;
  error?: string;
  containerStyle?: React.CSSProperties;
  horizontal?: boolean;
}

export default function CheckSelect({
  label,
  value,
  list,
  hint,
  error,
  containerStyle,
  onChange,
  horizontal,
}: CheckSelectProps) {
  const [selected, setSelected] = useState("");
  const speaker = (text: string | JSX.Element) => (
    <Popover style={{ width: 300 }}>{text}</Popover>
  );

  useEffect(() => {
    if (value && value !== selected) {
      setSelected(value);
    }
  }, [value]);

  const handleChange = (value: string) => {
    setSelected(value);
    onChange && onChange(value);
  };

  return (
    <div className={styles.container} style={containerStyle}>
      <div className={styles.labelContainer}>
        {!hint ? (
          <label>{label}</label>
        ) : (
          <Whisper placement="auto" trigger="hover" speaker={speaker(hint)}>
            <div>
              <label>{label}</label>
              <FiAlertCircle style={{ marginLeft: 4 }} />
            </div>
          </Whisper>
        )}
      </div>
      <div
        className={
          horizontal ? styles.itemContainerHorizontal : styles.itemsContainer
        }
      >
        {list &&
          list.map(({ value, label, hint }) => (
            <div
              key={value}
              className={horizontal ? styles.itemHorizontal : styles.item}
            >
              {!hint ? (
                <>
                  <input
                    checked={selected === value}
                    type="checkbox"
                    onChange={() => handleChange(value)}
                  />
                  <label>{label}</label>
                </>
              ) : (
                <Whisper
                  placement="auto"
                  trigger="hover"
                  speaker={speaker(hint)}
                >
                  <div style={{ alignItems: "center", display: "flex" }}>
                    <input
                      checked={selected === value}
                      type="checkbox"
                      onChange={() => handleChange(value)}
                    />
                    <label>{label}</label>
                  </div>
                </Whisper>
              )}
            </div>
          ))}
      </div>
      {!!error && <p className={styles.error}>{error}</p>}
    </div>
  );
}
