import React, { useEffect, useState } from "react";
import { useController, useFormContext } from "react-hook-form";
import { Button } from "rsuite";
import { FiEdit2, FiTrash2 } from "react-icons/fi";
import { isEqual } from "underscore";

import PartnerModal from "./components/PartnerModal";

import { saveDataOnLocalStorage } from "../../../../utils/openingOfLegalEntity";
import { RegistrationFormData, Partner } from "../../types";
import styles from "./index.module.css";

interface PartnerItemProps {
  name: string;
  editPartner: () => void;
  deletePartner: () => void;
}

const PartnerItem = ({
  name,
  editPartner,
  deletePartner,
}: PartnerItemProps) => (
  <div className={styles.partner}>
    <div className={styles.partnerName}>
      <span>Nome:</span>
      <span>{name}</span>
    </div>
    <div className={styles.partnerActions}>
      <FiEdit2 cursor="pointer" size={16} onClick={editPartner} />
      <FiTrash2 cursor="pointer" size={16} onClick={deletePartner} />
    </div>
  </div>
);

export default function Partners() {
  const [showFormModal, setShowFormModal] = useState(false);
  const [isFormModalMounted, setIsFormModalMounted] = useState(false);
  const [selectedPartnerToEdit, setSelectedPartnerToEdit] = useState<
    Partner & { index: number }
  >();
  const { setValue, getValues, control } =
    useFormContext<RegistrationFormData>();

  const {
    field: { value: partners },
    fieldState: { error },
  } = useController({ control, name: "partners" });

  useEffect(() => {
    if (partners) {
      saveDataOnLocalStorage("partners", partners);
    }
  }, [partners]);

  const openPartnerModal = () => {
    setShowFormModal(true);
    setIsFormModalMounted(true);
  };

  const openEditModal = (partner: Partner, index: number) => {
    setSelectedPartnerToEdit({ ...partner, index });
    openPartnerModal();
  };

  const closeModal = () => {
    setSelectedPartnerToEdit(undefined);
    setShowFormModal(false);
    setTimeout(() => setIsFormModalMounted(false), 300);
  };

  const addNewPartner = (newPartner: Partner, isAdministrator: boolean) => {
    const newPartners = partners ? [...partners] : [];
    newPartners.push(newPartner);
    setValue("partners", newPartners, { shouldValidate: true });

    if (isAdministrator) {
      const administrators = getValues("administrators") || [];
      const {
        legalPerson,
        investedAmount,
        cnpj,
        isCnh,
        residencyProof,
        maritalStatus,
        ...newAdministrator
      } = newPartner;
      if (typeof isCnh === "boolean" && residencyProof && maritalStatus)
        administrators.push({
          ...newAdministrator,
          isCnh,
          residencyProof,
          maritalStatus,
        });
      setValue("administrators", administrators, { shouldValidate: true });
    }

    setShowFormModal(false);
  };

  const editPartner = (partner: Partner, isAdministrator: boolean) => {
    if (selectedPartnerToEdit) {
      const newPartners = partners ? [...partners] : [];
      newPartners[selectedPartnerToEdit.index] = partner;
      setValue("partners", newPartners, { shouldValidate: true });

      const { legalPerson, investedAmount, cnpj, ...administrator } = partner;

      const administrators = getValues("administrators") || [];
      const result = administrators.find((value) =>
        isEqual(value, administrator)
      );

      if (isAdministrator && !result) {
        const { isCnh, residencyProof, maritalStatus, ...newAdministrator } =
          administrator;
        if (typeof isCnh === "boolean" && residencyProof && maritalStatus)
          administrators.push({
            ...newAdministrator,
            isCnh,
            residencyProof,
            maritalStatus,
          });
        setValue("administrators", administrators, { shouldValidate: true });
      } else if (!isAdministrator && result) {
        const newAdministrators = administrators.filter(
          (value) => !isEqual(value, administrator)
        );
        setValue("administrators", newAdministrators, { shouldValidate: true });
      }

      closeModal();
    }
  };

  const deletePartner = (index: number) => {
    const newPartners = [...partners];
    newPartners.splice(index, 1);
    setValue("partners", newPartners, { shouldValidate: true });
  };

  return (
    <section className={styles.container}>
      <h4>Sócios</h4>
      {!!partners?.length && (
        <div className={styles.partners}>
          {partners.map((partner, index) => (
            <PartnerItem
              key={`partner-item-${index}`}
              editPartner={() => openEditModal(partner, index)}
              deletePartner={() => deletePartner(index)}
              {...partner}
            />
          ))}
        </div>
      )}
      {isFormModalMounted && (
        <PartnerModal
          show={showFormModal}
          closeModal={closeModal}
          addNewPartner={addNewPartner}
          editPartner={editPartner}
          selectedPartnerToEdit={selectedPartnerToEdit}
        />
      )}
      <Button className={styles.button} onClick={openPartnerModal}>
        Adicionar Sócio
      </Button>
      {!!error && <p className={styles.error}>{error.message}</p>}
    </section>
  );
}
