import React, { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider, SubmitErrorHandler } from "react-hook-form";
import { Button } from "rsuite";
import { useNavigate } from "react-router-dom";

import CompanyData from "./components/CompanyData";
import AddressData from "./components/AddressData";
import GeneralQuestions from "./components/GeneralQuestions";
import Partners from "./components/Partners";
import DescriptionActivity from "./components/DescriptionActivity";
import Administrators from "./components/Administrators";
import Alert from "../../components/Alert";

import {
  CONTACT_MESSAGE,
  getDataEntriesOnLocalStorage,
  isKeyOfRegistrationFormData,
  RegistrationFormDataEntries,
} from "../../utils/openingOfLegalEntity";
import { RegistrationFormData } from "./types";
import { saveOpeningOfLegalEntity, uploadFile } from "./actions";

import openingOfLegalEntitySchema from "./schema";
import styles from "./index.module.css";

export default function OpeningOfLegalEntity() {
  const [isValidPostalCodeSetted, setIsValidPostalCodeSetted] = useState(false);
  const [loading, setLoading] = useState(false);

  const { handleSubmit, setError, ...methods } = useForm<RegistrationFormData>({
    resolver: yupResolver(openingOfLegalEntitySchema),
    shouldUnregister: true,
  });

  const navigate = useNavigate();

  const onSubmit = async (data: RegistrationFormData) => {
    try {
      setLoading(true);

      const newPartners = data.partners.map(
        ({ rg, cpf, residencyProof, cnpj, cnh, ...partner }) => ({
          ...partner,
          rg: rg?.url,
          cpf: cpf?.url,
          residencyProof: residencyProof?.url,
          cnpj: cnpj?.url,
          cnh: cnh?.url,
        })
      );

      const newAdministrators = data.administrators.map(
        ({ rg, cpf, residencyProof, cnh, ...administrator }) => ({
          ...administrator,
          rg: rg?.url,
          cpf: cpf?.url,
          residencyProof: residencyProof?.url,
          cnh: cnh?.url,
        })
      );

      const newData = {
        ...data,
        partners: newPartners,
        administrators: newAdministrators,
      };

      await saveOpeningOfLegalEntity(newData);
      localStorage.removeItem("openingOfLegalEntity");
      navigate("/mensagem-de-confirmacao");
    } catch (error) {
      setLoading(false);
      console.log(error);

      Alert({
        type: "error",
        message:
          "Erro ao enviar o formulário, tente novamente ou entre em contato com o suporte.",
        duration: 5000,
        placement: "topStart",
      });
    }
  };

  const onInvalid: SubmitErrorHandler<RegistrationFormData> = (errors) => {
    if (!isValidPostalCodeSetted && !errors.postalCode) {
      setError("postalCode", {
        type: "manual",
        message: "Pressione o botão para buscar o endereço",
      });
    }
  };

  useEffect(() => {
    const dataEntries = getDataEntriesOnLocalStorage();
    dataEntries.map(([key, value]) => {
      if (!key.match(/^postalCode$|^streetSuffix$|^street$|^district$|^city$/g))
        methods.setValue(key, value);
    });
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <header>
          <h3>Abertura de Pessoa Jurídica</h3>
          <p>
            Para iniciar a abertura de sua empresa, o preenchimento deste
            formulário é indispensável.
          </p>
          <p>
            A Royal Advice iniciará seu processo imediatamente após o
            recebimento das informações.
          </p>
          <p>
            Preencha corretamente os dados solicitados e envie o formulário.
          </p>
          <p>
            Se tiver dúvidas fale conosco:{" "}
            <a
              rel="noopener noreferrer"
              target="_blank"
              href={`${process.env.REACT_APP_WHATSAPP_URL || ""}${encodeURI(
                CONTACT_MESSAGE
              )}`}
            >
              WhatsApp (68) 3226-4050.
            </a>
          </p>
        </header>

        <FormProvider
          handleSubmit={handleSubmit}
          setError={setError}
          {...methods}
        >
          <form onSubmit={handleSubmit(onSubmit, onInvalid)}>
            <CompanyData />
            <DescriptionActivity />
            <AddressData
              isValidPostalCodeSetted={isValidPostalCodeSetted}
              setIsValidPostalCodeSetted={setIsValidPostalCodeSetted}
            />
            <Partners />
            <Administrators />
            <GeneralQuestions />
            <footer>
              <Button loading={loading} type="submit">
                Enviar
              </Button>
            </footer>
          </form>
        </FormProvider>
      </div>
    </div>
  );
}
