import React, { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import axios from "axios";

import Input from "../../../../components/Input";
import CheckSelect from "../../../../components/CheckSelect";
import Alert from "../../../../components/Alert";

import {
  PROPERTY_TYPE_LIST,
  filterJustNumbers,
  isKeyOfRegistrationFormData,
  maskCurrency,
  saveDataOnLocalStorage,
  getDataEntriesOnLocalStorage,
} from "../../../../utils/openingOfLegalEntity";
import { AddressApiResponseData, RegistrationFormData } from "../../types";
import styles from "./index.module.css";

interface AddressDataProps {
  isValidPostalCodeSetted: boolean;
  setIsValidPostalCodeSetted: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function AddressData({
  isValidPostalCodeSetted,
  setIsValidPostalCodeSetted,
}: AddressDataProps) {
  const [streetSuffixDisabled, setStreetSuffixDisabled] = useState(false);
  const [streetDisabled, setStreetDisabled] = useState(false);
  const [districtDisabled, setDistrictDisabled] = useState(false);
  const [cityDisabled, setCityDisabled] = useState(false);

  const {
    register,
    setValue,
    getValues,
    setError,
    clearErrors,
    control,
    formState: { errors },
  } = useFormContext<RegistrationFormData>();

  const setAddressFieldsWithLocalStorage = (postalCode: string) => {
    setValue("postalCode", postalCode);
    const dataEntries = getDataEntriesOnLocalStorage();
    const matches = dataEntries.filter(([key]) =>
      key.match(/^streetSuffix$|^street$|^district$|^city$/g)
    );
    if (matches.length) {
      setIsValidPostalCodeSetted(true);
      matches.map(([key, value]) => {
        switch (key) {
          case "streetSuffix":
            setStreetSuffixDisabled(true);
            break;
          case "street":
            setStreetDisabled(true);
            break;
          case "district":
            setDistrictDisabled(true);
            break;
          case "city":
            setCityDisabled(true);
            break;
        }
        setValue(key, value);
      });
    }
  };

  useEffect(() => {
    const localStorageData = JSON.parse(
      localStorage.getItem("openingOfLegalEntity") || "{}"
    ) as RegistrationFormData;
    const { postalCode } = localStorageData;
    if (postalCode) setAddressFieldsWithLocalStorage(postalCode);
  }, []);

  const searchByPostalCode = async (setLoading: (loading: boolean) => void) => {
    try {
      setLoading(true);
      const postalCode = getValues("postalCode");
      const rawPostalCode = postalCode.replaceAll(" ", "").replace("-", "");

      if (rawPostalCode.length !== 8 || Number.isNaN(Number(rawPostalCode))) {
        throw "O CEP inserido está incorreto";
      }

      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      setTimeout(() => {
        source.cancel();
        throw "Estamos com problemas para buscar o endereço, tente novamente mais tarde";
      }, 10000);

      const token = process.env.REACT_APP_NFE_TOKEN || "";
      const url = `${
        process.env.REACT_APP_NFE_API_BASE_URL || ""
      }/v1/addresses/${rawPostalCode}`;
      const config = {
        headers: { Authorization: `Bearer ${token}` },
        cancelToken: source.token,
      };

      const { data }: AddressApiResponseData = await axios.get(url, config);

      const {
        streetSuffix,
        street,
        district,
        city: { name: city },
      } = data;

      if (streetSuffix) {
        setValue("streetSuffix", streetSuffix, { shouldValidate: true });
        saveDataOnLocalStorage("streetSuffix", streetSuffix);
        setStreetSuffixDisabled(true);
      }

      if (street) {
        setValue("street", street, { shouldValidate: true });
        saveDataOnLocalStorage("street", street);
        setStreetDisabled(true);
      }

      if (district) {
        setValue("district", district, { shouldValidate: true });
        saveDataOnLocalStorage("district", district);
        setDistrictDisabled(true);
      }

      if (city) {
        setValue("city", city, { shouldValidate: true });
        saveDataOnLocalStorage("city", city);
        setCityDisabled(true);
      }

      clearErrors("postalCode");
      setIsValidPostalCodeSetted(true);
      setLoading(false);
    } catch (error) {
      let message = "Ocorreu um erro inesperado ao buscar o endereço";
      if (typeof error === "string") {
        message = error;
      } else if (axios.isAxiosError(error)) {
        switch (error.response?.status) {
          case 404:
            message = "O CEP inserido não existe";
            setError("postalCode", { type: "manual", message });
            break;
          case 500:
            message =
              "Estamos com problemas para buscar o endereço, tente novamente mais tarde";
            break;
        }
      }

      Alert({ type: "error", message, placement: "topStart", duration: 5000 });
      setLoading(false);
    }
  };

  const enableAllFields = () => {
    setStreetSuffixDisabled(false);
    setStreetDisabled(false);
    setDistrictDisabled(false);
    setCityDisabled(false);
  };

  const clearPostalCodeField = () => {
    const localStorageData = JSON.parse(
      localStorage.getItem("openingOfLegalEntity") || "{}"
    ) as RegistrationFormData;
    const {
      postalCode,
      iptu,
      streetSuffix,
      street,
      number,
      district,
      city,
      complement,
      totalBuildingArea,
      usedArea,
      ...newLocalStorageData
    } = localStorageData;
    localStorage.setItem(
      "openingOfLegalEntity",
      JSON.stringify(newLocalStorageData)
    );
    enableAllFields();
    setIsValidPostalCodeSetted(false);
    setValue("postalCode", "", { shouldValidate: true });
  };

  const handleOnChangeFilterJustNumbers = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const name = e.target.name;
    const value = filterJustNumbers(e.target.value);

    e.target.value = value;
    if (isKeyOfRegistrationFormData(name)) {
      setValue(name, value, { shouldValidate: true });
    }
  };

  const handleOnChangeMaskCurrency = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const name = e.target.name;
    const value = maskCurrency(e.target.value);

    e.target.value = value;
    if (isKeyOfRegistrationFormData(name)) {
      setValue(name, value, { shouldValidate: true });
    }
  };

  const handleOnBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;
    if (isKeyOfRegistrationFormData(name)) {
      saveDataOnLocalStorage(name, value);
    }
  };

  return (
    <section>
      <h4>Endereço da Empresa</h4>
      <Controller
        name="propertyType"
        control={control}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <CheckSelect
            value={value}
            onChange={(v) => {
              onChange(v);
              saveDataOnLocalStorage("propertyType", v);
            }}
            error={error?.message}
            label="Natureza do Imóvel *"
            list={PROPERTY_TYPE_LIST}
          />
        )}
      />
      <Input
        label="CEP *"
        register={register("postalCode", { onBlur: handleOnBlur })}
        error={errors.postalCode?.message}
        containerStyle={{ width: 200 }}
        disabledWithActivationOption={isValidPostalCodeSetted}
        clearField={clearPostalCodeField}
        search={searchByPostalCode}
        searchIcon
      />
      {isValidPostalCodeSetted && (
        <>
          <div className={styles.fieldSections}>
            <Input
              label="Inscrição Mobiliária (IPTU) *"
              register={register("iptu", { onBlur: handleOnBlur })}
              containerStyle={{ flex: 1 }}
              error={errors.iptu?.message}
            />
            <Input
              label="Tipo Logradouro *"
              disabled={streetSuffixDisabled}
              register={register("streetSuffix", { onBlur: handleOnBlur })}
              containerStyle={{ flex: 1 }}
              error={errors.streetSuffix?.message}
            />
          </div>
          <div className={styles.fieldSections}>
            <Input
              label="Logradouro *"
              disabled={streetDisabled}
              register={register("street", { onBlur: handleOnBlur })}
              containerStyle={{ flex: 3 }}
              error={errors.street?.message}
            />
            <Input
              label="N° Logradouro *"
              register={register("number", {
                onChange: handleOnChangeFilterJustNumbers,
                onBlur: handleOnBlur,
              })}
              containerStyle={{ flex: 1 }}
              error={errors.number?.message}
            />
          </div>
          <div className={styles.fieldSections}>
            <Input
              label="Bairro/Distrito *"
              disabled={districtDisabled}
              register={register("district", { onBlur: handleOnBlur })}
              containerStyle={{ flex: 1 }}
              error={errors.district?.message}
            />
            <Input
              label="Município"
              disabled={cityDisabled}
              register={register("city", { onBlur: handleOnBlur })}
            />
          </div>
          <Input
            label="Complemento"
            register={register("complement", { onBlur: handleOnBlur })}
          />
          <div className={styles.fieldSections}>
            <Input
              label="Área Total da Edificação (m²) *"
              register={register("totalBuildingArea", {
                onChange: handleOnChangeMaskCurrency,
                onBlur: handleOnBlur,
              })}
              hint="Exemplo: Tamanho prédio comercial, casa, galeria, etc."
              containerStyle={{ flex: 1 }}
              error={errors.totalBuildingArea?.message}
            />
            <Input
              label="Área Utilizada (m²) *"
              register={register("usedArea", {
                onChange: handleOnChangeMaskCurrency,
                onBlur: handleOnBlur,
              })}
              hint="Exemplo: Tamanho da sala comercial"
              containerStyle={{ flex: 1 }}
              error={errors.usedArea?.message}
            />
          </div>
        </>
      )}
    </section>
  );
}
