import React, { useEffect, useState } from "react";
import { useController, useFormContext, useWatch } from "react-hook-form";
import { Button } from "rsuite";
import { FiEdit2, FiTrash2 } from "react-icons/fi";

import AdministratorModal from "./components/AdministratorsModal";

import { saveDataOnLocalStorage } from "../../../../utils/openingOfLegalEntity";
import { RegistrationFormData } from "../../types";
import styles from "./index.module.css";

type CustomFile =
  | {
      url: string;
      type: string;
    }
  | undefined;

interface Administrator {
  name: string;
  nationality: string;
  maritalStatus: string;
  marriageRegime?: string;
  rg?: CustomFile;
  cpf?: CustomFile;
  isCnh: boolean;
  cnh?: CustomFile;
  residencyProof: CustomFile;
}

interface AdministratorItemProps {
  name: string;
  editAdministrator: () => void;
  deleteAdministrator: () => void;
}

const AdministratorItem = ({
  name,
  editAdministrator,
  deleteAdministrator,
}: AdministratorItemProps) => (
  <div className={styles.administrator}>
    <div className={styles.administratorName}>
      <span>Nome:</span>
      <span>{name}</span>
    </div>
    <div className={styles.administratorActions}>
      <FiEdit2 cursor="pointer" size={16} onClick={editAdministrator} />
      <FiTrash2 cursor="pointer" size={16} onClick={deleteAdministrator} />
    </div>
  </div>
);

export default function Administrators() {
  const [showFormModal, setShowFormModal] = useState(false);
  const [isFormModalMounted, setIsFormModalMounted] = useState(false);
  const [selectedAdministratorToEdit, setSelectedAdministratorToEdit] =
    useState<Administrator & { index: number }>();
  const { setValue, control } = useFormContext<RegistrationFormData>();

  const {
    field: { value: administrators },
    fieldState: { error },
  } = useController({ control, name: "administrators" });

  useEffect(() => {
    if (administrators) {
      saveDataOnLocalStorage("administrators", administrators);
    }
  }, [administrators]);

  const openAdministratorModal = () => {
    setShowFormModal(true);
    setIsFormModalMounted(true);
  };

  const openEditModal = (administrator: Administrator, index: number) => {
    setSelectedAdministratorToEdit({ ...administrator, index });
    openAdministratorModal();
  };

  const closeModal = () => {
    setSelectedAdministratorToEdit(undefined);
    setShowFormModal(false);
    setTimeout(() => setIsFormModalMounted(false), 300);
  };

  const addNewAdministrator = (newAdministrator: Administrator) => {
    const newAdministrators = administrators ? [...administrators] : [];
    newAdministrators.push(newAdministrator);
    setValue("administrators", newAdministrators, { shouldValidate: true });
    setShowFormModal(false);
  };

  const editAdministrator = (administrator: Administrator) => {
    if (selectedAdministratorToEdit) {
      const newAdministrators = administrators ? [...administrators] : [];
      newAdministrators[selectedAdministratorToEdit.index] = administrator;
      setValue("administrators", newAdministrators, { shouldValidate: true });
      closeModal();
    }
  };

  const deleteAdministrator = (index: number) => {
    const newAdministrators = [...administrators];
    newAdministrators.splice(index, 1);
    setValue("administrators", newAdministrators, { shouldValidate: true });
  };

  return (
    <section className={styles.container}>
      <h4>Administradores</h4>
      {!!administrators?.length && (
        <div className={styles.administrators}>
          {administrators.map((administrator, index) => (
            <AdministratorItem
              key={`administrator-item-${index}`}
              editAdministrator={() => openEditModal(administrator, index)}
              deleteAdministrator={() => deleteAdministrator(index)}
              {...administrator}
            />
          ))}
        </div>
      )}
      {isFormModalMounted && (
        <AdministratorModal
          show={showFormModal}
          closeModal={closeModal}
          addNewAdministrator={addNewAdministrator}
          editAdministrator={editAdministrator}
          selectedAdministratorToEdit={selectedAdministratorToEdit}
        />
      )}
      <Button className={styles.button} onClick={openAdministratorModal}>
        Adicionar Administrador
      </Button>
      {!!error && <p className={styles.error}>{error.message}</p>}
    </section>
  );
}
